<template>
  <div class="login-view">
    <div class="login-bg">
      <img src="../../assets/img/login_bg.png">
    </div>
    <div class="login-right">
      <div class="content-wrapper">
        <img src="../../assets/img/logo.png">
        <div class="app-name">上建砼泵管理系统</div>
        <div class="form-wrapper">
          <el-form ref="dataForm" :model="dataForm" :rules="dataRule" @keyup.enter.native="dataFormSubmit()"
            status-icon>
            <el-form-item prop="username">
              <el-input v-model="dataForm.username" placeholder="帐号"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="dataForm.password" type="password" placeholder="密码"></el-input>
            </el-form-item>
            <!-- <el-form-item prop="captcha" class="flex-row">
              <el-input v-model="dataForm.captcha" placeholder="验证码"></el-input>
              <img :src="captchaPath" @click="getCaptcha()">
            </el-form-item> -->
            <el-form-item style="margin-bottom: 0;">
              <el-checkbox v-model="dataForm.keepAlive" style="width: 100%; text-align: left;">7天内免登陆</el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button class="submit-button" type="danger" @click="dataFormSubmit()">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div style="margin-top: 46%;">ICP备案号：<a style="color:#666" target="_blank" href="http://beian.miit.gov.cn/">沪ICP备2024103931号-1</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isHoldToken: false,
      dataForm: {
        username: '',
        password: '',
        uuid: '',
        // captcha: '',
        keepAlive: false
      },
      dataRule: {
        username: [{
          required: true,
          message: '帐号不能为空',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        }]
        // captcha: [{
        //   required: true,
        //   message: '验证码不能为空',
        //   trigger: 'blur'
        // }]
      },
      captchaPath: ''
    }
  },

  methods: {
    // 提交表单
    dataFormSubmit () {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl('/login/login'),
            method: 'post',
            data: this.$http.adornData({
              'userName': this.dataForm.username,
              'password': this.dataForm.password,
              // 'code': this.dataForm.captcha,
              'keepAlive': this.dataForm.keepAlive
            })
          }).then((data) => {
            if (data && data.code === 200) {
              if (this.dataForm.keepAlive) {
                this.$cookie.set('token', data.datas, 7)
                this.$cookie.set('isHoldToken', 'true', 7)
              } else {
                this.$cookie.set('token', data.datas)
              }
              this.$router.push({
                name: 'home'
              })
              // window.location.href = '/home'
            } else {
              this.getCaptcha()
            }
          })
        }
      })
    },

    // 获取验证码
    getCaptcha () {
      this.$http({
        url: this.$http.adornUrl('/login/captcha'),
        method: 'post'
      }).then((data) => {
        this.dataForm.uuid = data.datas.token
        this.$cookie.set('token', data.datas.token)
        this.captchaPath = `data:image/jpg;base64,${data.datas.captcha}`
      })
    }
  },

  created () {
    this.isHoldToken = this.$cookie.get('isHoldToken') === 'true'
    if (this.isHoldToken) {
      this.$router.push({
        name: 'home'
      })
    } else {
      this.getCaptcha()
    }
  }
}
</script>

<style lang="scss">
  .login-view {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;

    .login-bg {
      width: 70%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .login-right {
      width: 30%;
    }

    .content-wrapper {
      width: 350px;
      margin: 0 auto;
      text-align: center;
      padding: calc((100vh - 470px) * 0.3) 0 0;
    }

    .app-name {
      font-size: 30px;
      color: #1a1a1a;
      margin: 20px 0 80px;
    }

    .flex-row .el-form-item__content {
      display: flex;

      img {
        margin-left: 20px;
        height: 36px;
      }
    }

    .submit-button {
      margin-top: 40px;
      width: 100%;
      background: #e70012;
      color: #fff;
    }
  }
</style>
